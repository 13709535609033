.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    margin: 0;
    padding: 0;
    overflow: hidden; /* Prevent scrolling */
    background-color: white; /* Optional: background color */
  }
  
  .App-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .LottieAnimation {
    max-height: 60%; /* Scale animation to fit viewport height */
    max-width: 60%; /* Scale animation to fit viewport width */
    object-fit: contain; /* Ensure aspect ratio is maintained */
  }
  
  .App-link {
    color: #61dafb;
  }
  
  /* App.css */
  .fade-logo {
    transition: opacity 2s ease-in-out;
  }
  
  .fade-in-logo {
    opacity: 1;
  }
  
  .fade-out-logo {
    opacity: 0;
  }
  
  .move-to-corner {
    animation: fadeAndMove 2.5s forwards ease-in-out;
  }
  
  @keyframes fadeAndMove {
    0% {
      opacity: 1;
      transform: translate(0, 0);
    }
    100% {
      opacity: 0;
      transform: translate(0vw, 0vh) scale(0.7); /* Adjust for precise positioning */
    }
  }